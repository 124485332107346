.richText h1,
.richText h2,
.richText h3,
.richText h4,
.richText h5,
.richText h6,
.richText h1 strong,
.richText h2 strong,
.richText h3 strong,
.richText h4 strong,
.richText h5 strong,
.richText h6 strong,
.richText strong {
  color: #000 !important;
}
.mapboxgl-ctrl-logo {
  display: none !important;
}

div[role="main"] > span:first-child {
  height: 100vh;
  width: 100%;
  display: block
}

/* To set scrollbar width */
/* 
body {
  scrollbar-width: thin;
  scrollbar-color: #9A9C9D #fff;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 9px;
  border: 1px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-track-piece {
	background-color: #FFF;
}

::-webkit-scrollbar-thumb {
  background: #9A9C9D; 
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
} */
